import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-help-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-section.component.html',
  styleUrl: './help-section.component.css',
})
export class HelpSectionComponent {
  public phoneNumber = '05 22 36 36 36';

  constructor(
    private router: Router,
    private platform: Platform,
    private clipboard: Clipboard
  ) {}

  @ViewChild('prePreFooterRef') prePreFooterRef: ElementRef;
  isSmallScreen: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkPrePreFooterSize();
  }

  ngAfterViewInit() {
    this.checkPrePreFooterSize();
    this.monitorResize();
  }

  monitorResize() {
    window.addEventListener('resize', this.checkPrePreFooterSize.bind(this));
  }

  checkPrePreFooterSize = () => {
    if (this.prePreFooterRef) {
      this.isSmallScreen =
        this.prePreFooterRef.nativeElement.offsetWidth <= 1024;
    }
  };

  // goToContact() {
  //   this.router.navigate(['/contact']);
  // }
}
