<div class="pre-pre-footer"  #prePreFooterRef [ngClass]="{'small-screen-height': isSmallScreen}">
  <div class="assistance">
    <div class="assistance-text" >
      <div class="titre">
        <h3>Besoin d'assistance</h3>
        <h3> ou de conseils ?</h3>
      </div>
      <div class="contact-info" [ngClass]="{ 'flex-column': isSmallScreen }">
        <a href="tel:+212522363636" class="btn phone rounded-pill px-3 py-2">
          {{phoneNumber}}
          <img src="assets/images/casqueblack.svg" alt="Phone icon">
        </a>
        <div class="horaires d-block d-md-none" [ngClass]="{ 'flex-row': isSmallScreen }">
          <h4 class="text-black text-center">*Appels uniquement de 8h à 16h30</h4>
        </div>
        <a class="btn phone message-button rounded-pill px-3 py-2" href="/contact">Envoyer un Message<img
            src="/assets/images/fi-rr-paper-plane.svg"></a>
          </div>
          <div class="horaires d-none d-md-block" [ngClass]="{ 'flex-row': isSmallScreen }">
          <h4 class="text-black text-center">* Appels uniquement de 8h à 16h30</h4>
        </div>
    </div>
    <div class="image-res">
      <img src="/assets/images/img-helpSectionVmobile.png" loading="lazy">
    </div>
    <div class="assistance-image">
      <img src="/assets/images/assist.png" alt="Assistance Image" loading="lazy">
    </div>
  </div>
</div>
